import React from "react"
import Layout from "../components/layout"

const Admin = () => {
  return (
    <Layout>
      <a
        href="https://zjednoczeni-ponad-podzialami.herokuapp.com/admin"
        className="block my-24 pt-24 text-link text-center mx-auto"
      >
        Panel administracyjny
      </a>
    </Layout>
  )
}

export default Admin
